// eslint-disable-next-line import/prefer-default-export
export default function catalogMenuItems(active, params = {}) {
  const menuItems = [
    {
      name: 'settings-catalog-defaults',
      layout: 'Defaults',
    },
    {
      name: 'settings-catalog-pricing',
      layout: 'Pricing',
    },
    {
      name: 'settings-catalog-skus',
      layout: 'SKUs',
    },
    {
      name: 'settings-catalog-kits',
      layout: 'Kits',
    },
    {
      name: 'settings-catalog-categories',
      layout: 'Categories',
    },
    {
      name: 'settings-catalog-brands',
      layout: 'Brands',
    },
  ]
  menuItems.forEach(item => {
    // eslint-disable-next-line no-param-reassign
    item.active = item.name === active
    // eslint-disable-next-line no-param-reassign
    item.route = { name: item.name, params }
  })
  return {
    menuItems,
  }
}
